import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useParams } from "react-router-dom";
import logo from "./components/logo.png";
import AcceptModal from "./AcceptModal";

const EstimateViewer = ({ estimateId }) => {
  const params = useParams();
  const [detailInvoice, setDetailInvoice] = useState(null);
  const estimateNumber = params.estimateNumber;
  const openModal = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER}/estimates/view/${estimateNumber}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const text = await response.text();

      if (!text) {
        throw new Error("Empty response received");
      }

      const data = JSON.parse(text);
      setDetailInvoice(data[0]);
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };

  useEffect(() => {
    openModal();
  }, []);
  return (
    <>
      {detailInvoice ? (
        <div className="estimate-holder">
          <div className="estimate-header">
            <div className="estimate-info">
              <img src={logo} alt="Logo" className="estimate-logo" />
              <div className="estimate-header-title">
                <span>Greenwood Landscape Maintenance</span>
                <span>Estimate #{detailInvoice.estimateNumber}</span>
              </div>
            </div>
            <div className="customer-info">
              <div className="customer-body">
                <span>Bill To:</span>
                <span>
                  Name: <b>{detailInvoice.customerName}</b>
                </span>
                <span>
                  Address: <b>1234 Address Rd</b>
                </span>
              </div>
            </div>
          </div>

          <div className="estimate-body">
            <div className="estimate-body-title">
              <span>Description:</span>
            </div>{" "}
            <div className="estimate-desc">
              <span>{detailInvoice.estimateNotes}</span>
            </div>
          </div>

          <div className="estimate-footer">
            <div className="estimate-amount">
              <span>Amount: ${detailInvoice.amount}</span>
            </div>
          </div>

          <div>
            <form className="estimate-form">
              <Button variant="success" size="lg">
                CONTACT
              </Button>

              {detailInvoice.accepted != 1 && (
                <AcceptModal
                  id={detailInvoice.id}
                  amount={detailInvoice.amount}
                  number={detailInvoice.estimateNumber}
                />
              )}

              {detailInvoice.accepted === 1 && (
                <Button variant="success" size="lg">
                  THIS ESTIMATE HAS BEEN ACCEPTED
                </Button>
              )}
            </form>
          </div>
        </div>
      ) : (
        "Loading..."
      )}
    </>
  );
};

export default EstimateViewer;
