import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import EstimateViewer from "./EstimateViewer";
import 'bootstrap/dist/css/bootstrap.min.css';



function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/view/:estimateNumber" element={<EstimateViewer />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
