import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function AcceptModal({ id, amount, number }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const acceptEstimate = async () => {
    try {

      const dataToSend = {
        id: Number(id),
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER}/estimates/accept/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const text = await response.text();

      if (!text) {
        throw new Error("Empty response received");
      }

      const data = JSON.parse(text);
      console.log(data);
      handleClose()
      window.location.reload()
    } catch (error) {
      console.error("Error accepting invoice:", error);
    }
  };
  return (
    <>
      <Button variant="success" size="lg" onClick={handleShow}>
        ACCEPT ESTIMATE
      </Button>

      <Modal show={show} onHide={handleClose}>
        <div className="accept-modal">
          <span className="accept-span">
            Accept Estimate #{number} for ${amount}
          </span>
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            CLOSE
          </Button>
          <Button variant="success" onClick={acceptEstimate}>
            ACCEPT
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AcceptModal;
